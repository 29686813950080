export const useFoodSubFamilyStore = defineStore('food-sub-family', () => {
  const $api = useApi();

  const allSubFamilies = ref([]);
  const activeSubFamilyId = ref();

  const activeSubFamily = computed(() =>
    allSubFamilies.value.find((f) => f.id === activeSubFamilyId.value),
  );

  function setActiveSubFamilyId(subFamilyId: string) {
    activeSubFamilyId.value = subFamilyId;
  }

  async function fetchAllSubFamilies() {
    const { data: families } = await $api.get(`/food/subfamily/all`);
    if (families) {
      allSubFamilies.value = families;
    }
  }

  async function fetchDetailedSubFamily(subFamilyId: string) {
    const { data: subFamily } = await $api.get(
      `/food/subfamily/${subFamilyId}`,
    );
    if (subFamily) {
      const subFamilyToUpdateIndex = allSubFamilies.value.findIndex(
        (f) => f.id === subFamilyId,
      );
      if (subFamilyToUpdateIndex != -1) {
        allSubFamilies.value[subFamilyToUpdateIndex] = subFamily;
      } else {
        allSubFamilies.value.push(subFamily);
      }
      setActiveSubFamilyId(subFamilyId);
    }
  }

  async function updateFoodSubSubFamily(
    familyId: string,
    data: Record<string, string>,
  ) {
    const { data: familyUpdated } = await $api.put(
      `/food/subfamily/${familyId}`,
      data,
    );
    if (familyUpdated) {
      const familyToUpdateIndex = allSubFamilies.value.findIndex(
        (n) => n.id === familyId,
      );
      allSubFamilies.value[familyToUpdateIndex] = {
        ...allSubFamilies.value[familyToUpdateIndex],
        ...data,
      };
    }
  }

  return {
    allSubFamilies,
    activeSubFamily,
    fetchAllSubFamilies,
    fetchDetailedSubFamily,
    updateFoodSubSubFamily,
  };
});
